import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Card, notification,Space } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Joi from "joi-browser";
import http from "../../helpers/http";

import config from "../../config/config";
import Logo from "../../logo-black.png";
const Login = (props) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [otpVal, setOtpVal] = useState("");
  const [otploading, setotpLoading] = useState(false);
  const [otpresend, setotpresend] = useState(false);
  const [visibility, setvisibility] = useState({
    form: true,
    otp: false,
  });
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const base_url = config.api_url + "admin/auth/login";

  //validation schema
  const schema = Joi.object({
    email: Joi.required(),

    password: Joi.required(),
  }).options({ allowUnknown: true });

  const validate = (data) => {
    const { error } = Joi.validate(data, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = validate(value);

    setFormError(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);

    const data = await http.post(base_url, value);

    if (data) {
      // const token = data.data.token;
      // localStorage.setItem(config.jwt_store_key, token);
      // notification["success"]({
      //   message: `Login Successfully`,
      // });
      // window.location = "/leads/application";
      setValues(value);
      setvisibility({
        form: false,
        otp: true,
      });

      form.resetFields();
    }
    setloading(false);
  };


  const handleotpverify = async (value) => {
    setFormError({});
    if(!value.otp){
      setFormError({otp:"OTP is required"});
      return
    }
    setloading(true);
    // let form_values = _.omit(values, ["password"]);
    const data = await http.post(base_url + "/otp/verify", {
      ...values,
      otp: value.otp,
    });

    if (data) {
      

      const token = data.data.token;
      localStorage.setItem(config.jwt_store_key, token);
      notification["success"]({
        message: `Login Successfully`,
      });
      window.location = "/2024/leads/application";
    }
    setloading(false);
  };

  const handlresendotp = async (e) => {
    setotpLoading(true);
    const { data } = await http.post(base_url, values);

    if (data) {
      
      notification["success"]({
        message: `OTP resend Successfully`,
      });
      // hiding the message after 5 sec
     
    }

    setotpLoading(false);
  };

  return (
    <div className="login_form_wrapper">
      <Row>
        <Col span={12} className="gutter-row">
          {visibility?.form &&  <Card
            title=""
            className="login-box"
            bordered={false}
            style={{ width: 400 }}
          >
            <figure className="raymond_login_logo_wrapper">
              <img src={Logo} style={{ width: 350 }} />
            </figure>
            <Form onFinish={handleSubmit} form={form}>
              <Form.Item
                name="email"
                {...(formError.email && {
                  help: formError.email,
                  validateStatus: "error",
                })}
              >
                <Input prefix={<UserOutlined />} placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="password"
                {...(formError.password && {
                  help: formError.password,
                  validateStatus: "error",
                })}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </Card> }
         


          {visibility?.otp && <Card
            title=""
            className="login-box"
            bordered={false}
            style={{ width: 400 }}
          >
            <figure className="raymond_login_logo_wrapper">
              <img src={Logo} style={{ width: 350 }} />
            </figure>
            <Form onFinish={handleotpverify} form={form}>
              
              <Form.Item
              required
                name="otp"
                {...(formError.otp && {
                  help: formError.otp,
                  validateStatus: "error",
                })}
              >
                <Input
                  prefix={<LockOutlined />}
                  placeholder="OTP"
                />
              </Form.Item>

              <Form.Item>
                <Space>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={loading}
                >
                 Verify
                </Button>
                <Button
                  type="primary"
                  size="large"
                  onClick={handlresendotp}
                  loading={otploading}
                  
                 
                >
                 {otploading ? "Sending..." : "Resend OTP"}
                </Button>
                </Space>
              </Form.Item>
            </Form>
          </Card>}
        </Col>
      </Row>
    </div>
  );
};

export default Login;
