import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Drawer,
  Descriptions,
  Anchor,
  Tag,
  Space,
  Button,
  Switch,
  Form,
  Input,
  Select,
  notification,
} from "antd";
import { Table } from "ant-table-extensions";
import http from "../../helpers/http";
import helper from "../../helpers/helper";
import config from "../../config/config";
import moment from "moment";
function Index() {
  const [loading, setloading] = useState(false);
  const [drawerOpen, setdraweropen] = useState(false);
  const [datas, setDatas] = useState([]);
  const [data, setData] = useState({});
  const [user, setUser] = useState({});
  const [btnloading, setbtnloading] = useState(false);
  const [form] = Form.useForm();
  const moduleNamePural = "Application Form";
  const base_url = config.api_url + "admin/users"; //without trailing slash
  const image_url = config.api_url + "uploads/user/"; //with trailing slash

  const { Option } = Select;

  const handleView = async (id) => {
    const { data } = await http.get(`${base_url}/${id}`);

    if (data) {
      setData(data);
      console.log(data);
      setdraweropen(true);
    }
  };

  const handleDrawerClose = () => {
    setData({});
    setdraweropen(false);
  };

  const fetchDatas = async (status = "all", semifinalist = "all") => {
    setloading(true);
    const data = await http.get(
      `${base_url}?status=${status}&semifinalist=${semifinalist}`
    );

    if (data) {
      setDatas(data.data);
      console.log(data.data);
    }
    setloading(false);
  };

  useEffect(() => {
    fetchDatas();
    setUser(helper.getUser());
    console.log(helper.getUser());
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return `${record.first_name}  ${record.last_name}`;
      },
      sorter: (a, b) => a.first_name?.localeCompare(b.first_name),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email?.localeCompare(b.email),
    },
    // {
    //   title: "Phone",
    //   dataIndex: "phone",
    //   key: "phone",
    //   render: (text, record) => {
    //     return `${record.country_code}${record.phone}`;
    //   },
    //   // sorter: (a, b) => a.phone?.localeCompare(b.phone),
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return record.status == "registered" ? (
          <Tag color="red">{record.status}</Tag>
        ) : record.status == "completed" ? (
          <Tag color="green">{record.status}</Tag>
        ) : (
          <Tag color="orange">partially filled</Tag>
        );
      },
    },
    // {
    //   title: "Approved by Nykaa?",
    //   dataIndex: "is_nykaa_approved",
    //   key: "is_nykaa_approved",
    //   render: (text, record) => {
    //     return user.permission.includes("nykaa_approve") ? (
    //       <Space size="middle">
    //         <Switch
    //           checkedChildren="Yes"
    //           unCheckedChildren="No"
    //           onChange={(value) => {
    //             handleStatus("is_nykaa_approved", value, record._id);
    //           }}
    //           defaultChecked={record.is_nykaa_approved}
    //         />
    //       </Space>
    //     ) : record.is_nykaa_approved ? (
    //       <Tag color="green">Yes</Tag>
    //     ) : (
    //       <Tag color="red">No</Tag>
    //     );
    //   },
    // },
    {
      title: "Approved by Estee?",
      dataIndex: "is_estee_approved",
      key: "is_estee_approved",
      render: (text, record) => {
        return user.permission.includes("estee_approve") ? (
          <Space size="middle">
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(value) => {
                handleStatus("is_estee_approved", value, record._id);
              }}
              defaultChecked={record.is_estee_approved}
            />
          </Space>
        ) : record.is_estee_approved ? (
          <Tag color="green">Yes</Tag>
        ) : (
          <Tag color="red">No</Tag>
        );
      },
    },
    {
      title: "Finalist?",
      dataIndex: "is_semifinalist",
      key: "is_semifinalist",
      render: (text, record) => {
        return user?.permission.includes("final_approve") ? (
          <Space size="middle">
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(value) => {
                handleStatus("is_semifinalist", value, record._id);
              }}
              defaultChecked={record.is_semifinalist}
            />
          </Space>
        ) : record.is_semifinalist ? (
          <Tag color="green">Yes</Tag>
        ) : (
          <Tag color="red">No</Tag>
        );
      },
    },
    {
      title: "Created At",
      render: (text, record) => {
        return helper.ISTDate(record.createdAt);
      },
      key: "createdAt",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              handleView(record._id);
            }}
          >
            View Detail
          </Button>
        </Space>
      ),
    },
  ];

  const fields = {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    // phone: "Mobile Number",
    // phone: {
    //   header: "Mobile Number",
    //   formatter: (_fieldValue, record) => {
    //     return record.country_code + record.phone;
    //   },
    // },
    title: "Title",
    comp_name: "Company Name",
    
    head_location: {
      header: "Headquarter Location",
      formatter: (_fieldValue, record) => {
        return record.countryData[0]?.name;
      },
    },
    address1: "Address 1",
    address2: "Address 2",
    
    state: {
      header: "State",
      formatter: (_fieldValue, record) => {
        return record.stateData[0]?.name;
      },
    },
    
    city: {
      header: "City",
      formatter: (_fieldValue, record) => {
        return record.cityData[0]?.name;
      },
    },
    zip: "Zip",
    // company_phone: "Company Phone",
    is_university_student: "Are you a university student?",
    university_name: "University Name",
    company_website: "Company Website",
    instagram: "Instagram",
    facebook: "Facebook",
    youtube: "Youtube",
    tiktok: "Tiktok",
    linkedin: "Linkedin",
    snapchat: "Snapchat",
    other_social:"Other Social handle",
    buss_type:
      "Are you a Company/ Partnership/ Sole proprietorship/Pre-Launch Concept?",
    gst_no: "GST No",
    
    date_of_launch: {
      header: "Date Of Launch",
      formatter: (_fieldValue, record) => {
        return record.date_of_launch?helper.ISTDate(record.date_of_launch,"YYYY-MM-DD"):null;
      },
    },
   
    emp_count: {
      header: "Number Of Employees",
      formatter: (_fieldValue, record) => {
        return  record.emp_count?    "( "+record.emp_count+" )":null;
      },
    },
    buss_ref_name: "Bussiness Reference Name",
    buss_ref_designation: "Bussiness Reference Designation",
    buss_ref_email: "Bussiness Reference Email",
    in_currently_market: "Is your company currently in-market?",
    selling_product_category: "In which product categories do you sell?",
    other_selling_product_category:"Other",
    target_age: "What age demographic are you targetting?",
    other_target_age:"Other",
    elevator_pitch: "company elevator pitch",
    brand_desc: "Describe your brand and business",
    addressing_opp: "What market opportunity is your company addressing",
    is_sustain_mission: "Do you have a sustainability mission?",
    mission: "What is your mission?",
    sustain_focus_category: "in which categories are you focusing?",
    other_sustain_focus_category:"Other",
    revenue_2021: "What were your 2021 revenues?",
    revenue_india: "How much of your company revenue comes from India?",
    distribution_channel: "What are your distribution channels?",
    other_distribution_channel:"Other",
    traction_info: "Do you have any metrics or information around traction",
    investment_to_date: "Investment to date",
    market_win_reason: "Why you will win the market",
    extra_info: "Anything else you'd like us to know",

    buss_overview: {
      header: "upload a one page overview of your business.",
      formatter: (_fieldValue, record) => {
        return (record.buss_overview)? image_url + record.buss_overview :null;
      },
    },
    // buss_overview_link: "overview of your business video link",
    buss_video_link: "video explaining your business.",
   

    status: {
      header: "Status",
      formatter: (_fieldValue, record) => {
        return record.status == "registered"
          ? record.status
          : record.status == "completed"
          ? record.status
          : "Partially Filled";
      },
    },
   
    is_estee_approved: {
      header: "Approved by Estee?",
      formatter: (_fieldValue, record) => {
        return record.is_estee_approved ? "Yes" : "No";
      },
    },
    is_semifinalist: {
      header: "finalist?",
      formatter: (_fieldValue, record) => {
        return record.is_semifinalist ? "Yes" : "No";
      },
    },

    createdAt: {
      header: "Created At",
      formatter: (_fieldValue, record) => {
        return helper.ISTDate(record.createdAt);
      },
    },
  };

  const handleSubmit = async (value) => {
    // let errors = await validate(value);

    // setFormError(errors);

    // if (Object.keys(errors).length) {
    //   return;
    // }

    setbtnloading(true);
    fetchDatas(value.status, value.semifinalist);
    setbtnloading(false);
  };

  const handleStatus = async (key, value, id) => {
    const data = value ? 1 : 0;

    const result = await http.put(`${base_url}/status/${id}`, {
      [key]: data,
    });

    if (result) {
      notification["success"]({
        message: "Status Changed Successfully",
      });
    }
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Leads</Breadcrumb.Item>
        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={15}>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card title={moduleNamePural} bordered={false}>
              <Form layout="inline" form={form} onFinish={handleSubmit}>
                <Form.Item
                  label="Status:"
                  name="status"
                  // {...(formError.status && {
                  //   help: formError.status,
                  //   validateStatus: "error",
                  // })}
                  style={{ width: 200 }}
                >
                  <Select defaultValue="all">
                    <Option value="all">All</Option>
                    <Option value="registered">Registered</Option>
                    <Option value="partial">Partially Filled</Option>
                    <Option value="completed">Completed</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Finalist:"
                  name="semifinalist"
                  // {...(formError.status && {
                  //   help: formError.status,
                  //   validateStatus: "error",
                  // })}
                >
                  <Select defaultValue="all">
                    <Option value="all">All</Option>
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={btnloading}>
                    Filter
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card bordered={false}>
              <Table
                rowKey={(record) => record._id}
                columns={columns}
                dataSource={datas}
                loading={loading}
                exportableProps={
                  user?.permission?.includes("export")
                    ? {
                        showColumnPicker: false,
                        fields,
                        fileName: moduleNamePural,
                      }
                    : null
                }
                
              />
            </Card>

            <Drawer
              title="Lead Details"
              placement="right"
              onClose={handleDrawerClose}
              visible={drawerOpen}
              size="large"
              width={1000}
            >
              {data && (
                <Descriptions
                  bordered
                  column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                  size="small"
                >
                  <Descriptions.Item label="First Name">
                    {data.first_name || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Last Name">
                    {data.last_name || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email">
                    {data.email || "NA"}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="Phone">
                    {data.country_code}
                    {data.phone || "NA"}
                  </Descriptions.Item> */}
                  <Descriptions.Item label="Title/Designation">
                    {data.title || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Company Name">
                    {data.comp_name || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Headquarter Location">
                    {data.countryData?.name || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Address 1">
                    {data.address1 || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Address 2">
                    {data.address2 || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="State">
                    {data.stateData?.name || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="City">
                    {data.cityData?.name || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Zip">
                    {data.zip || "NA"}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="Company Phone">
                    {data.company_phone || "NA"}
                  </Descriptions.Item> */}
                  <Descriptions.Item label="Are you a university student?">
                    {data.is_university_student || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="University name">
                    {data.university_name || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Company website">
                    {data.company_website || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Instagram">
                    {data.instagram || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Facebook">
                    {data.facebook || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Youtube">
                    {data.youtube || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Tiktok">
                    {data.tiktok || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Linkedin">
                    {data.linkedin || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Snapchat">
                    {data.snapchat || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Other Social handle">
                    {data.other_social || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Are you a Company/ Partnership/ Sole proprietorship/Pre-Launch Concept?">
                    {data.buss_type || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="GST No">
                    {data.gst_no || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Date of company launch">
                    {data.date_of_launch
                      ? helper.ISTDate(data.date_of_launch, "YYYY-MM-DD")
                      : "NA"}
                    {}
                  </Descriptions.Item>
                  <Descriptions.Item label="Number of Employees">
                    {data.emp_count || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Business reference name">
                    {data.buss_ref_name || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Business reference designation">
                    {data.buss_ref_designation || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Business reference email">
                    {data.buss_ref_email || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Is your company currently in-market?">
                    {data.in_currently_market || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="In which product categories do you sell?">
                    {data.selling_product_category || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Other">
                    {data.other_selling_product_category || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="What age demographic are you targetting?">
                    {data.target_age || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Other">
                    {data.other_target_age || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="company elevator pitch">
                    {data.elevator_pitch || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Describe your brand and business">
                    {data.brand_desc || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="What market opportunity is your company addressing">
                    {data.addressing_opp || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="What is your mission?">
                    {data.mission || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Do you have a sustainability mission?">
                    {data.is_sustain_mission || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="in which categories are you focusing?">
                    {data.sustain_focus_category || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Other">
                    {data.other_sustain_focus_category || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="What were your 2021 revenues?">
                    {data.revenue_2021 || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="How much of your company revenue comes from India?">
                    {data.revenue_india || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="What are your distribution channels?">
                    {data.distribution_channel || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Other">
                    {data.other_distribution_channel || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Do you have any metrics or information around traction">
                    {data.traction_info || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Investment to date">
                    {data.investment_to_date || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Why you will win the market">
                    {data.market_win_reason || "NA"}
                  </Descriptions.Item>
                  <Descriptions.Item label="Anything else you’d like us to know">
                    {data.extra_info || "NA"}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="upload a one page overview of your business.">
                    {data.buss_overview || "NA"}
                  </Descriptions.Item> */}
                  <Descriptions.Item label="upload a one page overview of your business.">
                    {data.buss_overview ? (
                      <a href={image_url + data.buss_overview} target="_blank">
                        View Overview
                      </a>
                    ) : (
                      "NA"
                    )}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="overview of your business video link">
                    {data.buss_overview_link ? (
                      <a href={data.buss_overview_link} target="_blank">
                        {data.buss_overview_link}
                      </a>
                    ) : (
                      "NA"
                    )}
                  </Descriptions.Item> */}
                  <Descriptions.Item label="video explaining your business.">
                    {data.buss_video_link ? (
                      <a href={data.buss_video_link} target="_blank">
                        {data.buss_video_link}
                      </a>
                    ) : (
                      "NA"
                    )}
                  </Descriptions.Item>

                  {/* <Descriptions.Item label="status">
                    {data.status}
                  </Descriptions.Item>
                  <Descriptions.Item label="Approved by Estee">
                    {data.is_estee_approved}
                  </Descriptions.Item>
                  <Descriptions.Item label="Approved by Nykaa">
                    {data.is_nykaa_approved}
                  </Descriptions.Item>
                  <Descriptions.Item label="Is Semifinalist">
                    {data.is_semifinalist}
                  </Descriptions.Item> */}

                  <Descriptions.Item label="Created At">
                    {helper.ISTDate(data.createdAt)}
                  </Descriptions.Item>
                </Descriptions>
              )}
            </Drawer>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Index;
