import React, { useEffect, useState, useRef } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Drawer,
  Descriptions,
  Anchor,
  Tag,
  Space,
  Button,
  Switch,
  Form,
  Input,
  Select,
  notification,
} from "antd";

import WebViewer from "@pdftron/webviewer";

// import { Viewer } from "@react-pdf-viewer/core";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
// import { Worker } from "@react-pdf-viewer/core"; // install this library

// import DocViewer, { PDFRenderer } from "react-doc-viewer";

function Preview(props) {
  const viewer = useRef(null);

  let document;

  if (props.match.params.type == "pdf") {
    document =
      "http://139.59.48.12:3007/uploads/user/1659446739678file-example_PDF_1MB.pdf";
  }

  if (props.match.params.type == "ppt") {
    document =
      "http://139.59.48.12:3007/uploads/user/1659534456206SamplePPTFile_500kb.ppt";
  }

  if (props.match.params.type == "doc") {
    document =
      "https://api.beautyandyouawards.com/uploads/user/1659422506054file-sample_1MB.docx";
  }

  useEffect(() => {
    WebViewer(
      {
        path: "/webviewer/lib",
        initialDoc: document,
      },
      viewer.current
    ).then((instance) => {
      const { documentViewer, annotationManager, Annotations } = instance.Core;

      instance.UI.disableElements(["ribbons"]);

      var Feature = instance.UI.Feature;
      // instance.UI.enableFeatures([Feature.Measurement]);
      instance.UI.disableFeatures([
        Feature.Download,
        Feature.Print,
        Feature.Annotations,
      ]);

      // documentViewer.addEventListener("documentLoaded", () => {
      //   const rectangleAnnot = new Annotations.RectangleAnnotation({
      //     PageNumber: 1,
      //     // values are in page coordinates with (0, 0) in the top left
      //     X: 100,
      //     Y: 150,
      //     Width: 200,
      //     Height: 50,
      //     Author: annotationManager.getCurrentUser(),
      //   });

      //   annotationManager.addAnnotation(rectangleAnnot);
      //   // need to draw the annotation otherwise it won't show up until the page is refreshed
      //   annotationManager.redrawAnnotation(rectangleAnnot);
      // });
    });
  }, []);

  const linkToPPTFile =
    "https://api.beautyandyouawards.com/uploads/user/1659422506054file-sample_1MB.docx";

  // const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const docs = [
    {
      uri: "http://139.59.48.12:3007/uploads/user/1659446739678file-example_PDF_1MB.pdf",
    },
  ];
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Leads</Breadcrumb.Item>
        <Breadcrumb.Item>{"Viewer"}</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card bordered={false} style={{ height: "80vh" }}>
              <>
                <iframe
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${linkToPPTFile}`}
                  width="100%"
                  height="600px"
                  frameBorder="0"
                  title="slides"
                ></iframe>
              </>

              {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.js">
                <div style={{ height: "750px" }}>
                  <Viewer
                    fileUrl="http://139.59.48.12:3007/uploads/user/1659446739678file-example_PDF_1MB.pdf"
                    // plugins={[defaultLayoutPluginInstance]}
                  />
                </div>
              </Worker> */}

              {/* <DocViewer documents={docs} pluginRenderers={PDFRenderer} /> */}

              {/* pdftron */}
              {/* <div className="webviewer" ref={viewer}></div> */}
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Preview;
