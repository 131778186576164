import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Upload,
  Row,
  Col,
  Select,
} from "antd";
import Joi from "joi-browser";
// import Joi from "joi";
import { PlusOutlined } from "@ant-design/icons";
import slugify from "react-slugify";
import http from "../../helpers/http";
import helper from "../../helpers/helper";
import config from "../../config/config";
import HtmlEditor from "../../components/HtmlEditor";
import axios from "axios";
import _ from "lodash";

const Create = ({ isVisible, handleClose, moduleName, categories }) => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [description, setDescription] = useState();

  const base_url = config.api_url + "admin/publishers"; //without trailing slash
  const image_url = config.api_url + "uploads/publisher/"; //with trailing slash
  const sizeLimit = 1;

  const { Option } = Select;
  //validation schema

  const schema = Joi.object({
   

    title: Joi.string().trim().required(),
    slug: Joi.string().trim().required(),

   
    image: Joi.required(),
  }).options({ allowUnknown: true });

  const validate = async (data) => {
    const { error } = Joi.validate(data, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    // validating unique slug
    if (data.slug) {
      const result = await helper.validateSlug(data.slug, "Publisher");

      if (result == false) {
        errors["slug"] = `This slug is already used.`;
      }
    }

    if (data.image) {
      if (data.image.file.size > sizeLimit * 1024 * 1024) {
        errors["image"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(data.image.file.type)) {
        errors["image"] = "File does not have a valid file extension.";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);
    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();
    for (const [key, data] of Object.entries(_.omit(value, ["image"]))) {
      formData.append(key, data || "");
    }

    // formData.append("title", value.title);

    // formData.append("description", value.description);
    // formData.append("website", value.website);
    // formData.append("pintrest", value.pintrest);
    // formData.append("facebook", value.facebook);
    // formData.append("linkedin", value.linkedin);
    // formData.append("youtube", value.youtube);
    // formData.append("twitter", value.twitter);
    // formData.append("instagram", value.instagram);
    // formData.append("brandCategory", value.brandCategory);

    formData.append("image", value.image.file);

    const { data } = await http.post(base_url, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Added Successfully`,
      });
      setloading(false);

      // form.resetFields();

      handleClose();
    }
  };

  return (
    <Modal
      title={`Add ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            

            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      // width={1000}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={15}>
        <Col span={24}>
            <Form.Item
              required
              label="Title:"
              name="title"
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input
                onChange={(value) => {
                  form.setFieldsValue({ slug: slugify(value.target.value) });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              required
              label="Slug:"
              name="slug"
              {...(formError.slug && {
                help: formError.slug,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          
        </Row>

      
       

      
        <Row gutter={15}>
         
          <Col span={24}>
            <Form.Item
              required
              label="Image:"
              name="image"
              {...(formError.image && {
                help: formError.image,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
              >
                <PlusOutlined />
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default Create;
