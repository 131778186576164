import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  notification,
  Upload,
  Row,
  Col,
  Select,
  DatePicker,
  InputNumber
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import Joi from "joi-browser";
import _ from "lodash";
import http from "../../../helpers/http";
import config from "../../../config/config";
import HtmlEditor from "../../../components/HtmlEditor";
import helper from "../../../helpers/helper";
import slugify from "react-slugify";
import moment from "moment";

function Edit({ isVisible, handleClose, dbData, moduleName, categories }) {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [formError, setFormError] = useState({});
  const [date, setDate] = useState();

  // const [description, setDescription] = useState();

  const base_url = config.api_url + "admin/2024/press"; //without trailing slash
  const image_url = config.api_url + "uploads/press-2024/"; //with trailing slash
  const sizeLimit = 1;

  const { Option } = Select;

  const schema = Joi.object({
    title: Joi.string().trim().required(),
    author: Joi.string().trim().required(),
    date: Joi.string().trim().required(),
    website: Joi.string().trim().required(),
    publisher: Joi.string().trim().required(),

    // description: Joi.string().trim().required(),
  }).options({ allowUnknown: true });

  const validate = async (value) => {
    const { error } = Joi.validate(value, schema, {
      abortEarly: false,
      allowUnknown: true,
    });

    const errors = {};

    if (error) {
      error.details.map((field) => {
        errors[field.path[0]] = field.message;
        return true;
      });
    }

    if (value.slug && dbData.slug != value.slug) {
      const result = await helper.validateSlug(value.slug, "Publisher");

      if (result == false) {
        errors["slug"] = `This slug is already used.`;
      }
    }

    if (value.image) {
      if (value.image.file.size > sizeLimit * 1024 * 1024) {
        errors["image"] = `File needs to be under ${sizeLimit}MB`;
      }
      let allowedExt = [
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/svg",
      ];
      if (!allowedExt.includes(value.image.file.type)) {
        errors["image"] = "File does not have a valid file extension.";
      }
    }

    return errors ? errors : null;
  };

  const handleSubmit = async (value) => {
    let errors = await validate(value);

    setFormError(errors);

    if (Object.keys(errors).length) {
      return;
    }

    setloading(true);
    let formData = new FormData();
    for (const [key, data] of Object.entries(_.omit(value, ["image"]))) {
      formData.append(key, data || "");
    }

    if (value.image) {
      formData.append("image", value.image.file);
    }

    const { data } = await http.put(`${base_url}/${dbData._id}`, formData);

    if (data) {
      notification["success"]({
        message: `${moduleName} Updated Successfully`,
      });
      setloading(false);
      form.resetFields();
      handleClose();
    }
  };
  useEffect(() => {
    form.setFieldsValue(_.omit(dbData, ["image", "date"]));
    setDate(moment(dbData.date).format("YYYY-MM-DD"));
    // setDescription(() => {
    //   return dbData.description;
    // });
  }, [dbData]);

  return (
    <Modal
      title={`Edit ${moduleName}`}
      visible={isVisible}
      onCancel={handleClose}
      okText="Update"
      cancelText="Cancel"
      confirmLoading={loading}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            values.date = date;
            handleSubmit(values);
          })
          .catch((info) => {});
      }}
      width={1000}
    >
      <Form layout="vertical" form={form}>
        <Row gutter={15}>
          <Col span={21}>
            <Form.Item
              required
              label="Title:"
              name="title"
              {...(formError.title && {
                help: formError.title,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              label="Sort Order"
              required
              name="sort_order"
              {...(formError.sort_order && {
                help: formError.sort_order,
                validateStatus: "error",
              })}
            >
              <InputNumber min={1} defaultValue={1} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              label="Publisher:"
              name="publisher"
              {...(formError.publisher && {
                help: formError.publisher,
                validateStatus: "error",
              })}
            >
              <Select>
                {categories &&
                  categories.map((data) => {
                    return <Option value={data._id}>{data.title}</Option>;
                  })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              required
              label="Author:"
              name="author"
              {...(formError.author && {
                help: formError.author,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              required
              label="Website:"
              name="website"
              {...(formError.website && {
                help: formError.website,
                validateStatus: "error",
              })}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              required
              label="Date:"
              name="date"
              {...(formError.date && {
                help: formError.date,
                validateStatus: "error",
              })}
            >
              <DatePicker
                style={{
                  width: "100%",
                }}
                defaultValue={moment(dbData.date)}
                onChange={(date, dateString) => {
                  setDate(dateString);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              label="Image:"
              name="image"
              required
              {...(formError.image && {
                help: formError.image,
                validateStatus: "error",
              })}
            >
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                maxCount={1}
                listType="picture-card"
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
                defaultFileList={[
                  {
                    thumbUrl: image_url + dbData.image,
                  },
                ]}
              >
                <EditOutlined />
              </Upload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default Edit;
