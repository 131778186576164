import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Drawer,
  Descriptions,
  Anchor,
  Tag,
  Space,
  Button,
  Switch,
  Form,
  Input,
  Select,
  List,
  notification,
} from "antd";
import { Table } from "ant-table-extensions";
import http from "../../helpers/http";
import helper from "../../helpers/helper";
import config from "../../config/config";
import moment from "moment";
function Index() {
  const [loading, setloading] = useState(false);

  const [datas, setDatas] = useState({});
  const [data, setData] = useState({});
  const [user, setUser] = useState({});
  const [btnloading, setbtnloading] = useState(false);
  const [form] = Form.useForm();
  const moduleNamePural = "Lead Dashboard";
  const base_url = config.api_url + "admin/users/dashboard"; //without trailing slash
  const image_url = config.api_url + "uploads/user/"; //with trailing slash

  const { Option } = Select;

  const fetchDatas = async () => {
    setloading(true);
    const data = await http.get(base_url);

    if (data) {
      setDatas(data.data);
    }
    setloading(false);
  };

  useEffect(() => {
    fetchDatas();
    setUser(helper.getUser());
    console.log(helper.getUser());
  }, []);
  if (loading) {
    return null;
  }
  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Leads</Breadcrumb.Item>
        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
      </Breadcrumb>

      <Row gutter={15}>
        {datas?.emp_count?.length > 0 ? (
          <Col span={6} style={{ marginBottom: 10 }}>
            <div className="site-card-border-less-wrapper">
              <Card title="Number of employees" bordered={false}>
                <Descriptions
                  bordered
                  column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                  size="small"
                >
                  {datas?.emp_count.map((data) => {
                    return data._id ? (
                      <Descriptions.Item label={data._id}>
                        {data.count}
                      </Descriptions.Item>
                    ) : null;
                  })}
                </Descriptions>
              </Card>
            </div>
          </Col>
        ) : null}
        {datas?.selling_product_category?.length > 0 ? (
          <Col span={6} style={{ marginBottom: 10 }}>
            <div className="site-card-border-less-wrapper">
              <Card title="Selling product categories" bordered={false}>
                <Descriptions
                  bordered
                  column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                  size="small"
                >
                  {datas?.selling_product_category.map((data) => {
                    return data._id ? (
                      <Descriptions.Item label={data._id}>
                        {data.count}
                      </Descriptions.Item>
                    ) : null;
                  })}
                </Descriptions>
              </Card>
            </div>
          </Col>
        ) : null}
        {datas?.target_age?.length > 0 ? (
          <Col span={6} style={{ marginBottom: 10 }}>
            <div className="site-card-border-less-wrapper">
              <Card title="Target Age" bordered={false}>
                <Descriptions
                  bordered
                  column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                  size="small"
                >
                  {datas?.target_age.map((data) => {
                    return data._id ? (
                      <Descriptions.Item label={data._id}>
                        {data.count}
                      </Descriptions.Item>
                    ) : null;
                  })}
                </Descriptions>
              </Card>
            </div>
          </Col>
        ) : null}
        {datas?.sustain_focus_category?.length > 0 ? (
          <Col span={6} style={{ marginBottom: 10 }}>
            <div className="site-card-border-less-wrapper">
              <Card title="Focused Category" bordered={false}>
                <Descriptions
                  bordered
                  column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                  size="small"
                >
                  {datas?.sustain_focus_category.map((data) => {
                    return data._id ? (
                      <Descriptions.Item label={data._id}>
                        {data.count}
                      </Descriptions.Item>
                    ) : null;
                  })}
                </Descriptions>
              </Card>
            </div>
          </Col>
        ) : null}

        <Col span={6} style={{ marginBottom: 10 }}>
          <div className="site-card-border-less-wrapper">
            <Card title="2021 Revenue" bordered={false}>
              <Descriptions
                bordered
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                size="small"
              >
                {datas?.revenue_2021?.map((data) => {
                  return data._id ? (
                    <Descriptions.Item label={data._id}>
                      {data.count}
                    </Descriptions.Item>
                  ) : null;
                })}
              </Descriptions>
            </Card>
          </div>
        </Col>

        <Col span={6} style={{ marginBottom: 10 }}>
          <div className="site-card-border-less-wrapper">
            <Card title="Distrubution Channel" bordered={false}>
              <Descriptions
                bordered
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                size="small"
              >
                {datas?.distribution_channel?.map((data) => {
                  return data._id ? (
                    <Descriptions.Item label={data._id}>
                      {data.count}
                    </Descriptions.Item>
                  ) : null;
                })}
              </Descriptions>
            </Card>
          </div>
        </Col>

        <Col span={6} style={{ marginBottom: 10 }}>
          <div className="site-card-border-less-wrapper">
            <Card title="Investment" bordered={false}>
              <Descriptions
                bordered
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                size="small"
              >
                {datas?.investment_to_date?.map((data) => {
                  return data._id ? (
                    <Descriptions.Item label={data._id}>
                      {data.count}
                    </Descriptions.Item>
                  ) : null;
                })}
              </Descriptions>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Index;
