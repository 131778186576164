import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Drawer,
  Descriptions,
  Anchor,
  Tag,
  Space,
  Button,
  Switch,
  Form,
  Input,
  Select,
  notification,
} from "antd";
import { Table } from "ant-table-extensions";
import http from "../../../helpers/http";
import helper from "../../../helpers/helper";
import config from "../../../config/config";
import moment from "moment";
function Index() {
  const [loading, setloading] = useState(false);
  const [drawerOpen, setdraweropen] = useState(false);
  const [datas, setDatas] = useState([]);
  const [data, setData] = useState({});
  const [user, setUser] = useState({});
  const [btnloading, setbtnloading] = useState(false);
  const [form] = Form.useForm();
  const moduleNamePural = "Application Form 2023";
  const base_url = config.api_url + "admin/2023/users"; //without trailing slash
  const image_url = config.api_url + "uploads/user-2023/"; //with trailing slash

  const { Option } = Select;

  const handleView = async (id) => {
    const { data } = await http.get(`${base_url}/${id}`);

    if (data) {
      setData(data);
      console.log(data);
      setdraweropen(true);
    }
  };

  const handleDrawerClose = () => {
    setData({});
    setdraweropen(false);
  };

  const fetchDatas = async (status = "all", semifinalist = "all") => {
    setloading(true);
    const data = await http.get(
      `${base_url}?status=${status}&semifinalist=${semifinalist}`
    );

    if (data) {
      setDatas(data.data);
      console.log(data.data);
    }
    setloading(false);
  };

  useEffect(() => {
    fetchDatas();
    setUser(helper.getUser());
    console.log(helper.getUser());
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return `${record.first_name}  ${record.last_name}`;
      },
      sorter: (a, b) => a.first_name?.localeCompare(b.first_name),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email?.localeCompare(b.email),
    },
    // {
    //   title: "Phone",
    //   dataIndex: "phone",
    //   key: "phone",
    //   render: (text, record) => {
    //     return `${record.country_code}${record.phone}`;
    //   },
    //   // sorter: (a, b) => a.phone?.localeCompare(b.phone),
    // },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text, record) => {
        return record.is_breakthrough_prize == "yes"
          ? "Breakthrough"
          : record.is_creative_prize == "yes"
          ? "Creative Excellence"
          : record.in_currently_market == "yes"
          ? "Grow"
          : record.in_currently_market == "no"
          ? "Imagine"
          : "NA";
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return record.status == "registered" ? (
          <Tag color="red">{record.status}</Tag>
        ) : record.status == "completed" ? (
          <Tag color="green">{record.status}</Tag>
        ) : (
          <Tag color="orange">partially filled</Tag>
        );
      },
    },
    // {
    //   title: "Approved by Nykaa?",
    //   dataIndex: "is_nykaa_approved",
    //   key: "is_nykaa_approved",
    //   render: (text, record) => {
    //     return user.permission.includes("nykaa_approve") ? (
    //       <Space size="middle">
    //         <Switch
    //           checkedChildren="yes"
    //           unCheckedChildren="no"
    //           onChange={(value) => {
    //             handleStatus("is_nykaa_approved", value, record._id);
    //           }}
    //           defaultChecked={record.is_nykaa_approved}
    //         />
    //       </Space>
    //     ) : record.is_nykaa_approved ? (
    //       <Tag color="green">Yes</Tag>
    //     ) : (
    //       <Tag color="red">No</Tag>
    //     );
    //   },
    // },
    // {
    //   title: "Approved by Estee?",
    //   dataIndex: "is_estee_approved",
    //   key: "is_estee_approved",
    //   render: (text, record) => {
    //     return user.permission.includes("estee_approve") ? (
    //       <Space size="middle">
    //         <Switch
    //           checkedChildren="yes"
    //           unCheckedChildren="no"
    //           onChange={(value) => {
    //             handleStatus("is_estee_approved", value, record._id);
    //           }}
    //           defaultChecked={record.is_estee_approved}
    //         />
    //       </Space>
    //     ) : record.is_estee_approved ? (
    //       <Tag color="green">Yes</Tag>
    //     ) : (
    //       <Tag color="red">No</Tag>
    //     );
    //   },
    // },
    // {
    //   title: "Finalist?",
    //   dataIndex: "is_semifinalist",
    //   key: "is_semifinalist",
    //   render: (text, record) => {
    //     return user?.permission.includes("final_approve") ? (
    //       <Space size="middle">
    //         <Switch
    //           checkedChildren="yes"
    //           unCheckedChildren="no"
    //           onChange={(value) => {
    //             handleStatus("is_semifinalist", value, record._id);
    //           }}
    //           defaultChecked={record.is_semifinalist}
    //         />
    //       </Space>
    //     ) : record.is_semifinalist ? (
    //       <Tag color="green">Yes</Tag>
    //     ) : (
    //       <Tag color="red">No</Tag>
    //     );
    //   },
    // },
    {
      title: "Created At",
      render: (text, record) => {
        return helper.ISTDate(record.createdAt);
      },
      key: "createdAt",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
    {
      title: "Completed At",
      render: (text, record) => {
        return helper.ISTDate(record.updatedAt);
      },
      key: "updatedAt",
      sorter: (a, b) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
     
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              handleView(record._id);
            }}
          >
            View Detail
          </Button>
        </Space>
      ),
    },
  ];

  const fields = {
    // category: "Category",
    category: {
      header: "Category",
      formatter: (_fieldValue, record) => {
        return record.is_breakthrough_prize == "yes"
          ? "Breakthrough"
          : record.is_creative_prize == "yes"
          ? "Creative Excellence"
          : record.in_currently_market == "yes"
          ? "Grow"
          : "Imagine";
      },
    },
    first_name: "First Name",

    last_name: "Last Name",
    email: "Email",
    // phone: "Mobile Number",
    phone: {
      header: "Phone",
      formatter: (_fieldValue, record) => {
        return record.country_code + record.phone;
      },
    },
    title: "Title",
    comp_name: "Company Name",

    head_location: {
      header: "Country",
      formatter: (_fieldValue, record) => {
        return record.countryData[0]?.name;
      },
    },
    address1: "Address 1",
    address2: "Address 2",

    state: {
      header: "State",
      formatter: (_fieldValue, record) => {
        return record.stateData[0]?.name;
      },
    },

    city: {
      header: "City",
      formatter: (_fieldValue, record) => {
        return record.cityData[0]?.name;
      },
    },
    zip: "Zip",
    // company_phone: "Company Phone",
    is_university_student: "Are you a university student?",

    university_name: "University Name",
    is_breakthrough_prize: "Is this a submission for the Breakthrough Prize?",
    is_creative_prize:
      "Is this a submission for the CREATE Prize?",
    company_website: "Company Website",
    instagram: "Instagram",
    facebook: "Facebook",
    youtube: "Youtube",
    tiktok: "Tiktok",
    linkedin: "Linkedin",
    snapchat: "Snapchat",
    other_social: "Other Social handle",
    buss_type:
      "Are you a Company/ Partnership/ Sole proprietorship/Pre-Launch Concept?",
    gst_no: "GST No or Tax ID",

    date_of_launch: {
      header: "Date Of Launch",
      formatter: (_fieldValue, record) => {
        return record.comp_launch_date
          ? `${record.comp_launch_date}-${record.comp_launch_month}-${record.comp_launch_year}`
          : "NA";
      },
    },

    emp_count: {
      header: "Number Of Employees",
      formatter: (_fieldValue, record) => {
        return record.emp_count ? "( " + record.emp_count + " )" : null;
      },
    },
    buss_ref_name: "Bussiness Reference Name",
    buss_ref_designation: "Bussiness Reference Designation",
    buss_ref_email: "Bussiness Reference Email",
    in_currently_market: "Is your company currently trading in-market?",

    buss_overview: {
      header: "upload a one page overview of your business.",
      formatter: (_fieldValue, record) => {
        return record.buss_overview ? image_url + record.buss_overview : null;
      },
    },
    buss_video_link: "video explaining your business.",

    selling_product_category: "In which product categories do you sell?",
    other_selling_product_category: "Other",
    target_age: "What age demographic are you targetting?",
    other_target_age: "Other",
    elevator_pitch: "Please provide your company 'elevator pitch'",
    brand_exp:
      "This year, finalists will be invited to create a brand experience in New Delhi.  The space will be approximately 120 square feet  and will be a pop up for 1-2 days.  Please describe the brand experience you would like to build ",
    brand_desc: "Describe your brand and business",
    addressing_opp: "What market opportunity is your company addressing",
    is_sustain_mission:
      "Do you have a sustainability or social impact mission?",
    mission: "What is your mission?",
    sustain_focus_category: "in which categories are you focusing?",
    other_sustain_focus_category: "Other",
    revenue_2022: "What were your 2022 revenues?",
    revenue_india: "How much of your company revenue comes from India?",
    distribution_channel: "What are your distribution channels?",
    other_distribution_channel: "Other",
    traction_info:
      "Do you have any information around interest (pre-launch) or traction (in market) that you would like to share with us",
    investment_to_date: "Investment to date",
    market_win_reason: "Why you will win the market",
    extra_info: "Anything else you'd like us to know",

    addressing_ingredient:
      "What issue area or product process / technology does your active ingredient address?",
    other_addressing_ingredient: "Other",

    break_elevator_pitch: {
      header: "Please provide your ingredient story 'elevator pitch'",
      formatter: (_fieldValue, record) => {
        return record.elevator_pitch;
      },
    },
    efficacy_evidence:
      "What is the evidence that demonstrates efficacy of your product",
    company_background:
      "Describe your company/background and your experience in this sector ",
    ingredient_market: "What is the market for your ingredient?",
    applicable_reason: "Why is it applicable to Indian consumers?",
    is_ingredient_sustain: "Is your ingredient considered sustainable?",
    ingredient_sustain_reason:
      "please describe why it is considered sustainable?",

    breakthrough_in_currently_market: "Are you currently in market?",

    work_summary:
      "Please provide a descriptive summary of your work that you have submitted",
    creative_background:
      "Describe your background and your experience in this creative space",
    agree_to_email:
      "Describe your background and your experience in this creative space",

    // buss_overview_link: "overview of your business video link",

    agree_to_email: {
      header: "Agree to receive Email",
      formatter: (_fieldValue, record) => {
        return record.agree_to_email ? "yes" : "no";
      },
    },
    agree_to_sms: {
      header: "Agree to receive SMS",
      formatter: (_fieldValue, record) => {
        return record.agree_to_sms ? "yes" : "no";
      },
    },
    status: {
      header: "Status",
      formatter: (_fieldValue, record) => {
        return record.status == "registered"
          ? record.status
          : record.status == "completed"
          ? record.status
          : "partially filled";
      },
    },

    is_estee_approved: {
      header: "Approved by Estee?",
      formatter: (_fieldValue, record) => {
        return record.is_estee_approved ? "yes" : "no";
      },
    },
    is_semifinalist: {
      header: "finalist?",
      formatter: (_fieldValue, record) => {
        return record.is_semifinalist ? "yes" : "no";
      },
    },

    createdAt: {
      header: "Created At",
      formatter: (_fieldValue, record) => {
        return helper.ISTDate(record.createdAt);
      },
    },
    updatedAt: {
      header: "Completed At",
      formatter: (_fieldValue, record) => {
        return helper.ISTDate(record.updatedAt);
      },
    },
  };

  const handleSubmit = async (value) => {
    // let errors = await validate(value);

    // setFormError(errors);

    // if (Object.keys(errors).length) {
    //   return;
    // }

    setbtnloading(true);
    fetchDatas(value.status, "all");
    setbtnloading(false);
  };

  const handleStatus = async (key, value, id) => {
    const data = value ? 1 : 0;

    const result = await http.put(`${base_url}/status/${id}`, {
      [key]: data,
    });

    if (result) {
      notification["success"]({
        message: "Status Changed Successfully",
      });
    }
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Leads</Breadcrumb.Item>
        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={15}>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card title={moduleNamePural} bordered={false}>
              <Form layout="inline" form={form} onFinish={handleSubmit}>
                <Form.Item
                  label="Status:"
                  name="status"
                  // {...(formError.status && {
                  //   help: formError.status,
                  //   validateStatus: "error",
                  // })}
                  style={{ width: 200 }}
                >
                  <Select defaultValue="all">
                    <Option value="all">All</Option>
                    <Option value="registered">Registered</Option>
                    <Option value="partial">Partially Filled</Option>
                    <Option value="completed">Completed</Option>
                  </Select>
                </Form.Item>

                {/* <Form.Item
                  label="Finalist:"
                  name="semifinalist"
                 
                >
                  <Select defaultValue="all">
                    <Option value="all">All</Option>
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                </Form.Item> */}
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={btnloading}>
                    Filter
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card bordered={false}>
              <Table
                rowKey={(record) => record._id}
                columns={columns}
                dataSource={datas}
                loading={loading}
                exportableProps={
                  user?.permission?.includes("export")
                    ? {
                        showColumnPicker: false,
                        fields,
                        fileName: moduleNamePural,
                      }
                    : null
                }
              />
            </Card>

            <Drawer
              title="Lead Details"
              placement="right"
              onClose={handleDrawerClose}
              visible={drawerOpen}
              size="large"
              width={1000}
            >
              {data && (
                <>
                  <Descriptions
                    title="Basic Details"
                    bordered
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                    size="small"
                  >
                    <Descriptions.Item label="First Name">
                      {data.first_name || "NA"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Last Name">
                      {data.last_name || "NA"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Email">
                      {data.email || "NA"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Phone">
                      {data.country_code}
                      {data.phone || "NA"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Agree to receive Email">
                      
                      {data.agree_to_email ? "Yes":"No"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Agree to receive SMS">
                      
                      {data.agree_to_sms ? "Yes":"No"}
                    </Descriptions.Item>

                    <Descriptions.Item label="Title/Designation">
                      {data.title || "NA"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Company Name">
                      {data.comp_name || "NA"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Country">
                      {data.countryData?.name || "NA"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Address 1">
                      {data.address1 || "NA"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Address 2">
                      {data.address2 || "NA"}
                    </Descriptions.Item>
                    <Descriptions.Item label="State">
                      {data.stateData?.name || "NA"}
                    </Descriptions.Item>
                    <Descriptions.Item label="City">
                      {data.cityData?.name || "NA"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Zip">
                      {data.zip || "NA"}
                    </Descriptions.Item>

                    <Descriptions.Item label="Are you a university student?">
                      {data.is_university_student || "NA"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Please provide University Name">
                      {data.university_name || "NA"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Is this a submission for the Breakthrough Prize?  ">
                      {data.is_breakthrough_prize || "NA"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Is this a submission for the CREATE Prize?">
                      {data.is_creative_prize || "NA"}
                    </Descriptions.Item>
                    <>
                      {data.is_breakthrough_prize == "no" &&
                        data.is_creative_prize == "no" && (
                          <>
                            <Descriptions.Item label="Company website">
                              {data.company_website || "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Instagram">
                              {data.instagram || "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Facebook">
                              {data.facebook || "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Youtube">
                              {data.youtube || "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Tiktok">
                              {data.tiktok || "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Linkedin">
                              {data.linkedin || "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Snapchat">
                              {data.snapchat || "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Other Social handle">
                              {data.other_social || "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Are you a Company/ Partnership/ Sole proprietorship/Pre-Launch Concept?">
                              {data.buss_type || "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="GST No or Tax ID">
                              {data.gst_no || "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Date of company launch">
                              {/* {data.date_of_launch
                        ? helper.ISTDate(data.date_of_launch, "YYYY-MM-DD")
                        : "NA"} */}

                              {data.comp_launch_date
                                ? `${data.comp_launch_date}-${data.comp_launch_month}-${data.comp_launch_year}`
                                : "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Number of Employees">
                              {data.emp_count || "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Business reference name">
                              {data.buss_ref_name || "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Business reference designation">
                              {data.buss_ref_designation || "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Business reference email">
                              {data.buss_ref_email || "NA"}
                            </Descriptions.Item>
                            <Descriptions.Item label="Is your company currently trading in-market?">
                              {data.in_currently_market || "NA"}
                            </Descriptions.Item>
                          </>
                        )}
                    </>

                    <Descriptions.Item label="Created At">
                      {helper.ISTDate(data.createdAt)}
                    </Descriptions.Item>
                  </Descriptions>

                  {data?.in_currently_market == "yes" &&
                    data?.is_breakthrough_prize == "no" &&
                    data?.is_creative_prize == "no" && (
                      <Descriptions
                        title="Grow"
                        bordered
                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                        size="small"
                        style={{ marginTop: 20 }}
                      >
                        <Descriptions.Item label="Please upload a one-page overview of your business idea with a description">
                          {data.buss_overview ? (
                            <a
                              href={image_url + data.buss_overview}
                              target="_blank"
                            >
                              View Overview
                            </a>
                          ) : (
                            "NA"
                          )}
                        </Descriptions.Item>

                        <Descriptions.Item label="Please upload a link to a 3-minute (max) video explaining your business">
                          {data.buss_video_link ? (
                            <a href={data.buss_video_link} target="_blank">
                              {data.buss_video_link}
                            </a>
                          ) : (
                            "NA"
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="In which product categories do you sell or plan to sell ">
                          {data.selling_product_category || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Other">
                          {data.other_selling_product_category || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="What age demographic are you targetting?">
                          {data.target_age || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Other">
                          {data.other_target_age || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Please provide your company 'elevator pitch'">
                          {data.elevator_pitch || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="This year, finalists will be invited to create a brand experience in New Delhi.  The space will be approximately 120 square feet  and will be a pop up for 1-2 days.  Please describe the brand experience you would like to build ">
                          {data.brand_exp || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Describe your brand and business">
                          {data.brand_desc || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="What market opportunity is your company addressing">
                          {data.addressing_opp || "NA"}
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="What is your mission?">
                        {data.mission || "NA"}
                      </Descriptions.Item> */}
                        <Descriptions.Item label="Do you have a sustainability or social impact mission?">
                          {data.is_sustain_mission || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="in which categories are you focusing?">
                          {data.sustain_focus_category || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Other">
                          {data.other_sustain_focus_category || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="What were your 2022 revenues?">
                          {data.revenue_2022 || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="How much of your company revenue comes from India?">
                          {data.revenue_india || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="What are your distribution channels?">
                          {data.distribution_channel || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Other">
                          {data.other_distribution_channel || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Do you have any information around interest (pre-launch) or traction (in market) that you would like to share with us?">
                          {data.traction_info || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Investment to date">
                          {data.investment_to_date || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Why you will win the market">
                          {data.market_win_reason || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Anything else you’d like us to know">
                          {data.extra_info || "NA"}
                        </Descriptions.Item>
                      </Descriptions>
                    )}
                  {data?.in_currently_market == "no" &&
                    data?.is_breakthrough_prize == "no" &&
                    data?.is_creative_prize == "no" && (
                      <Descriptions
                        title="Imagine"
                        bordered
                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                        size="small"
                        style={{ marginTop: 20 }}
                      >
                        <Descriptions.Item label="Please upload a one-page overview of your business idea with a description">
                          {data.buss_overview ? (
                            <a
                              href={image_url + data.buss_overview}
                              target="_blank"
                            >
                              View Overview
                            </a>
                          ) : (
                            "NA"
                          )}
                        </Descriptions.Item>

                        <Descriptions.Item label="Please upload a link to a 3-minute (max) video explaining your business">
                          {data.buss_video_link ? (
                            <a href={data.buss_video_link} target="_blank">
                              {data.buss_video_link}
                            </a>
                          ) : (
                            "NA"
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item label="In which product categories do you sell or plan to sell ">
                          {data.selling_product_category || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Other">
                          {data.other_selling_product_category || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="What age demographic are you targetting?">
                          {data.target_age || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Other">
                          {data.other_target_age || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Please provide your company 'elevator pitch'">
                          {data.elevator_pitch || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="This year, finalists will be invited to create a brand experience in New Delhi.  The space will be approximately 120 square feet  and will be a pop up for 1-2 days.  Please describe the brand experience you would like to build ">
                          {data.brand_exp || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Describe your brand and business">
                          {data.brand_desc || "NA"}
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="What market opportunity is your company addressing">
                      {data.addressing_opp || "NA"}
                    </Descriptions.Item> */}
                        <Descriptions.Item label="What is your mission?">
                          {data.mission || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Do you have a sustainability or social impact mission?">
                          {data.is_sustain_mission || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="in which categories are you focusing?">
                          {data.sustain_focus_category || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Other">
                          {data.other_sustain_focus_category || "NA"}
                        </Descriptions.Item>
                        {/* <Descriptions.Item label="What were your 2022 revenues?">
                      {data.revenue_2022 || "NA"}
                    </Descriptions.Item>
                    <Descriptions.Item label="How much of your company revenue comes from India?">
                      {data.revenue_india || "NA"}
                    </Descriptions.Item> */}
                        <Descriptions.Item label="What are your distribution channels?">
                          {data.distribution_channel || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Other">
                          {data.other_distribution_channel || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Do you have any information around interest (pre-launch) or traction (in market) that you would like to share with us?">
                          {data.traction_info || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Investment to date">
                          {data.investment_to_date || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Why you will win the market">
                          {data.market_win_reason || "NA"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Anything else you’d like us to know">
                          {data.extra_info || "NA"}
                        </Descriptions.Item>
                      </Descriptions>
                    )}
                  {data?.is_breakthrough_prize == "yes" && (
                    <Descriptions
                      title="Breakthrough Prize"
                      bordered
                      column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                      size="small"
                      style={{ marginTop: 20 }}
                    >
                      <Descriptions.Item label="Please upload a one-page overview of your active ingredient and the problem/solution it solves for Indian consumers.">
                        {data.buss_overview ? (
                          <a
                            href={image_url + data.buss_overview}
                            target="_blank"
                          >
                            View Overview
                          </a>
                        ) : (
                          "NA"
                        )}
                      </Descriptions.Item>

                      <Descriptions.Item label="If you have any video assets that explain your active ingredient and its efficacy story">
                        {data.buss_video_link ? (
                          <a href={data.buss_video_link} target="_blank">
                            {data.buss_video_link}
                          </a>
                        ) : (
                          "NA"
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="What issue area or product process / technology does your active ingredient address?">
                        {data.addressing_ingredient || "NA"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Other">
                        {data.other_addressing_ingredient || "NA"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Please provide your ingredient story 'elevator pitch'">
                        {data.elevator_pitch || "NA"}
                      </Descriptions.Item>
                      <Descriptions.Item label="What is the evidence that demonstrates efficacy of your product">
                        {data.efficacy_evidence || "NA"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Describe your company/background and your experience in this sector ">
                        {data.company_background || "NA"}
                      </Descriptions.Item>
                      <Descriptions.Item label="What is the market for your ingredient? ">
                        {data.ingredient_market || "NA"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Why is it applicable to Indian consumers?">
                        {data.applicable_reason || "NA"}
                      </Descriptions.Item>
                      {/* <Descriptions.Item label="What market opportunity is your company addressing">
                      {data.addressing_opp || "NA"}
                    </Descriptions.Item> */}
                      <Descriptions.Item label="Is your ingredient considered sustainable? ">
                        {data.is_ingredient_sustain || "NA"}
                      </Descriptions.Item>
                      <Descriptions.Item label="please describe why it is considered sustainable?">
                        {data.ingredient_sustain_reason || "NA"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Do you have any information around interest (pre-launch) or traction (in market) that you would like to share with us ">
                        {data.traction_info || "NA"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Why will you win the market ">
                        {data.market_win_reason || "NA"}
                      </Descriptions.Item>
                      {/* <Descriptions.Item label="What were your 2022 revenues?">
                      {data.revenue_2022 || "NA"}
                    </Descriptions.Item>
                    <Descriptions.Item label="How much of your company revenue comes from India?">
                      {data.revenue_india || "NA"}
                    </Descriptions.Item> */}
                      <Descriptions.Item label="Anything else you’d like us to know ">
                        {data.extra_info || "NA"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Are you currently in market?">
                        {data.breakthrough_in_currently_market || "NA"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Are you currently in discussions with The Estee Lauder Companies on your active ingredient?">
                        {data.estee_discussion || "NA"}
                      </Descriptions.Item>
                    </Descriptions>
                  )}

                  {data?.is_creative_prize == "yes" && (
                    <Descriptions
                      title="Creative Excellence Prize"
                      bordered
                      column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                      size="small"
                      style={{ marginTop: 20 }}
                    >
                      <Descriptions.Item label="Please upload an example of your creative work that speaks to the following creative brief.">
                        {data.buss_overview ? (
                          <a
                            href={image_url + data.buss_overview}
                            target="_blank"
                          >
                            View Overview
                          </a>
                        ) : (
                          "NA"
                        )}
                      </Descriptions.Item>

                      <Descriptions.Item label="If you have any video assets that you would like to share">
                        {data.buss_video_link ? (
                          <a href={data.buss_video_link} target="_blank">
                            {data.buss_video_link}
                          </a>
                        ) : (
                          "NA"
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="Please provide a descriptive summary of your work that you have submitted  ">
                        {data.work_summary || "NA"}
                      </Descriptions.Item>
                      <Descriptions.Item label="Describe your background and your experience in this creative space">
                        {data.creative_background || "NA"}
                      </Descriptions.Item>

                      <Descriptions.Item label="Anything else you’d like us to know ">
                        {data.extra_info || "NA"}
                      </Descriptions.Item>
                    </Descriptions>
                  )}
                </>
              )}
            </Drawer>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Index;
