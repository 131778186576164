import React, { useState } from "react";
import "./App.less";
import { Layout, Menu, Modal } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import {
  PieChartOutlined,
  UserOutlined,
  LogoutOutlined,
  CrownOutlined,
  LineChartOutlined,
  StarOutlined,
  ShopOutlined,
  GlobalOutlined,
  TrophyOutlined,
  PlayCircleOutlined,
  AimOutlined,
  AudioOutlined,
  PictureOutlined,
  FileTextOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";


import Application from "./pages/leads/Application";
import Application2023 from "./pages/2023/leads/Application";
import Application2024 from "./pages/2024/leads/Application";
import Finalist from "./pages/Finalist";
import Finalist2023 from "./pages/2023/Finalist";
import Finalist2024 from "./pages/2024/Finalist";
import Publisher from "./pages/Publisher/Index";
import Press from "./pages/Press/Index";
import Publisher2023 from "./pages/2023/Publisher/Index";
import Press2023 from "./pages/2023/Press/Index";
import Publisher2024 from "./pages/2024/Publisher/Index";
import Press2024 from "./pages/2024/Press/Index";

import Newsletter from "./pages/leads/Newsletter";
import Newsletter2024 from "./pages/2024/leads/Newsletter";
import Dashboard from "./pages/leads/Dashboard";

import Logout from "./components/Logout";

import Viewer from "./pages/leads/Viewer";

function MainLayout(props) {
  const { Header, Content, Footer, Sider } = Layout;
  const { SubMenu } = Menu;
  const { confirm } = Modal;
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (collapsed) => {
    setCollapsed(!collapsed);
  };

  const showLogoutConfirm = (id) => {
    confirm({
      title: `Are you sure you want to logout?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        // return handleDelete(id);
        props.history.push("/logout");
      },
      onCancel() {},
    });
  };
  return (
    <Router>
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          theme="dark"
          collapsible
          collapsed={collapsed}
          onCollapse={() => {
            onCollapse(collapsed);
          }}
        >
          {/* <div className="logo">
            <span>Raymond</span>
            <img src={Logo} className="img-fluid" />
          </div> */}
          <Menu theme="dark" mode="inline">
           
            <SubMenu key="leads" icon={<LineChartOutlined />} title="Leads">
              
              {/* <Menu.Item key="9">
                <Link to="/leads/application">Application</Link>
              </Menu.Item> */}
              <Menu.Item key="13">
                <Link to="/2023/leads/application">Application 2023</Link>
              </Menu.Item>
              <Menu.Item key="17">
                <Link to="/2024/leads/application">Application 2024</Link>
              </Menu.Item>
              <Menu.Item key="18">
                <Link to="/2024/leads/newsletter">Newsletter 2024</Link>
              </Menu.Item>
            </SubMenu>
            {/* <SubMenu key="press" icon={<LineChartOutlined />} title="Press Release">
              
              <Menu.Item key="10">
                <Link to="/publishers">Publishers</Link>
               
              </Menu.Item>
              <Menu.Item key="11">
              <Link to="/press">Press</Link>
               
              </Menu.Item>
            </SubMenu> */}
            {/* <SubMenu key="press-2023" icon={<LineChartOutlined />} title="Press Release 2023">
              
              <Menu.Item key="14">
                <Link to="/2023/publishers">Publishers</Link>
               
              </Menu.Item>
              <Menu.Item key="15">
              <Link to="/2023/press">Press</Link>
               
              </Menu.Item>
            </SubMenu> */}
            <SubMenu key="press-2024" icon={<LineChartOutlined />} title="Press Release 2024">
              
              <Menu.Item key="14">
                <Link to="/2024/publishers">Publishers</Link>
               
              </Menu.Item>
              <Menu.Item key="15">
              <Link to="/2024/press">Press</Link>
               
              </Menu.Item>
            </SubMenu>

            {/* <Menu.Item key="12" icon={<TrophyOutlined />}>
              <Link to="/finalists">Finalists</Link>
            </Menu.Item>
            <Menu.Item key="16" icon={<TrophyOutlined />}>
              <Link to="/2023/finalists">Finalists 2023</Link>
            </Menu.Item> */}
            <Menu.Item key="19" icon={<TrophyOutlined />}>
              <Link to="/2024/finalists">Finalists 2024</Link>
            </Menu.Item>

           
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <Menu theme="dark" mode="horizontal">
              <Menu.Item key="1" icon={<LogoutOutlined />}>
                <Link
                  onClick={() => {
                    showLogoutConfirm();
                  }}
                >
                  Logout
                </Link>
              </Menu.Item>
            </Menu>
          </Header>
          <Content style={{ margin: "0 16px" }}>
            <Switch>
              <Route exact path="/" component={Application2024} />

             
              <Route exact path="/leads/application" component={Application} />
              <Route exact path="/2023/leads/application" component={Application2023} />
              <Route exact path="/2024/leads/application" component={Application2024} />
              {/* <Route exact path="/leads/partner" component={Partner} /> */}
              <Route exact path="/leads/newsletter" component={Newsletter} />
              <Route exact path="/2024/leads/newsletter" component={Newsletter2024} />

              <Route exact path="/leads/Dashboard" component={Dashboard} />
              <Route exact path="/leads/viewer/:type" component={Viewer} />
              <Route exact path="/finalists" component={Finalist} />
              <Route exact path="/2023/finalists" component={Finalist2023} />
              <Route exact path="/2024/finalists" component={Finalist2024} />
              <Route exact path="/publishers" component={Publisher} />
              <Route exact path="/press" component={Press} />
              <Route exact path="/2023/publishers" component={Publisher2023} />
              <Route exact path="/2023/press" component={Press2023} />
              <Route exact path="/2024/publishers" component={Publisher2024} />
              <Route exact path="/2024/press" component={Press2024} />
             
              <Route exact path="/logout" component={Logout} />
            </Switch>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Design & Developed by Togglehead
          </Footer>
        </Layout>
      </Layout>
    </Router>
  );
}

export default MainLayout;
