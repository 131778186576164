import React, { useEffect, useState } from "react";
// import tinymce from "tinymce/tinymce";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Space,
  Button,
  Modal,
  notification,
  Switch,
  Image,
  Drawer,
  Anchor,
} from "antd";
import { Table } from "ant-table-extensions";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Edit from "./Edit";
import Create from "./Create";
import http from "../../helpers/http";
import helper from "../../helpers/helper";
import config from "../../config/config";

function Index() {
  const { confirm } = Modal;
  const [editOpen, setEditOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [viewerOpen, setViewerOpen] = useState(false);

  const [data, setData] = useState({});
  const [loading, setloading] = useState(false);
  const [categories, setCategories] = useState();

  //createKey and editKey is used to reset modal data on close
  const [createKey, setCreateKey] = useState(Math.random() * 10);
  const [editKey, seteditKey] = useState(Math.random() * 10);
  const [datas, setDatas] = useState([]);

  const moduleName = "Press";
  const moduleNamePural = "Press";
  const base_url = config.api_url + "admin/press"; //without trailing slash
  const image_url = config.api_url + "uploads/press/"; //with trailing slash

  const { Link } = Anchor;

  const handleEdit = async (id) => {
    const { data } = await http.get(`${base_url}/${id}`);

    if (data) {
      seteditKey((state) => {
        return state + Math.random() * 2;
      });
      setData(data);
      setEditOpen(true);
    }
  };
  const handleCreate = () => {
    setCreateKey((state) => {
      return state + Math.random() * 2;
    });
    setCreateOpen(true);
  };

  const handleViewer = () => {
    setViewerOpen(true);
  };

  const handleEditClose = () => {
    seteditKey((state) => {
      return state + Math.random() * 2;
    });
    setData({});
    setEditOpen(false);
    fetchDatas();
  };

  const handleCreateClose = () => {
    setCreateKey((state) => {
      return state + Math.random() * 2;
    });

    setCreateOpen(false);
    fetchDatas();
  };

  const handleViewerClose = () => {
    setViewerOpen(false);
  };

  const handleStatus = async (value, id) => {
    const status = value ? 1 : 0;

    const result = await http.put(`${base_url}/status/${id}`, {
      status: status,
    });

    if (result) {
      notification["success"]({
        message: "Status Changed Successfully",
      });
    }
  };

  const handleDelete = async (id) => {
    const result = await http.delete(`${base_url}/${id}`);
    if (result) {
      notification["success"]({
        message: `${moduleName} Deleted Successfully`,
      });
    }

    fetchDatas();
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: `Are you sure delete this ${moduleName}?`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        return handleDelete(id);
      },
      onCancel() {},
    });
  };

  const fetchDatas = async () => {
    setloading(true);
    const data = await http.get(base_url);

    if (data) {
      setDatas(data.data);
    }
    setloading(false);
  };

  const fetchCategory = async () => {
    const data = await http.get(config.api_url + "admin/publishers");

    if (data) {
      setCategories(data.data);
    }
  };

  useEffect(() => {
    fetchDatas();
    fetchCategory();
  }, []);

  const columns = [
    {
      title: "Image",

      key: "image",
      render: (text, record) => (
        <Image
          width={100}
          src={image_url + record.image}
          fallback="https://via.placeholder.com/100"
        />
      ),
    },

    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
   
    {
      title: "Date",
      render: (text, record) => {
        return helper.ISTDate(record.date,"YYYY-MM-DD");
      },
      key: "date",
      
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
    },
    
    {
      title: "Publisher",
      render: (text, record) => {
        return record.publisher?.title;
      },
      key: "publisher",
    },
    {
      title: "Sort Order",
      dataIndex: "sort_order",
      key: "sort_order",
    },

    {
      title: "Status",
      key: "status",
      render: (text, record) => (
        <Space size="middle">
          <Switch
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={(value) => {
              handleStatus(value, record._id);
            }}
            defaultChecked={record.status}
          />
        </Space>
      ),
    },

    
    
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="primary"
            onClick={() => {
              handleEdit(record._id);
            }}
          >
            Edit
          </Button>
          <Button
            type="danger"
            onClick={() => {
              showDeleteConfirm(record._id);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card title={moduleNamePural} bordered={false}>
              <Row style={{ marginBottom: "20px" }}>
                <Col span={24}>
                  <Button type="primary" onClick={handleCreate}>
                    Add {moduleName}
                  </Button>
                </Col>
              </Row>

              <Table
                rowKey={(record) => record._id}
                columns={columns}
                dataSource={datas}
                loading={loading}
              />

              <Edit
                key={"edit" + editKey}
                isVisible={editOpen}
                handleClose={handleEditClose}
                dbData={data}
                moduleName={moduleName}
                categories={categories}
              />
              <Create
                key={"create" + createKey}
                isVisible={createOpen}
                handleClose={handleCreateClose}
                moduleName={moduleName}
                categories={categories}
              />

              
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Index;
