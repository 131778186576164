import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Row,
  Col,
  Card,
  Drawer,
  Descriptions,
  Space,
  Button,
  Result,
} from "antd";
import { Table } from "ant-table-extensions";
import http from "../../helpers/http";
import helper from "../../helpers/helper";
import config from "../../config/config";
import _ from "lodash"
import { CSVLink } from "react-csv";
function Index() {
  const [loading, setloading] = useState(false);
  const [drawerOpen, setdraweropen] = useState(false);
  const [datas, setDatas] = useState([]);
  const [data, setData] = useState({});

  const moduleNamePural = "Finalists 2023";
  const base_url = config.api_url + "admin/2023/finalists"; //without trailing slash
  const image_url = config.api_url + "uploads/finalist-2023/"; //with trailing slash

  const handleView = async (id) => {
    const { data } = await http.get(`${base_url}/${id}`);

    if (data) {
      setData(data);
      console.log(data);
      setdraweropen(true);
    }
  };

  const handleDrawerClose = () => {
    setData({});
    setdraweropen(false);
  };

  const fetchDatas = async () => {
    setloading(true);
    const data = await http.get(base_url);

    if (data) {
      setDatas(data.data);
      console.log(data.data);
    }
    setloading(false);
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return `${record.name.toUpperCase()}`;
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text, record) => {
        return `${_.capitalize(record.category)}`;
      },
    },
    {
      title: "Votes",

      render: (text, record) => {
        return `${record.voters.length}`;
      },
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          {record.voters.length > 0 ? (
            <Space size="middle">
              <Button
                type="primary"
                onClick={() => {
                  handleView(record._id);
                }}
              >
                View Voters
              </Button>
              <CSVLink
                // data={record.voters}
                data={record.voters.map(row => ({...row, createdAt: helper.ISTDate(row.createdAt)}))}
                headers={headers}
                filename={`${record.name} - Voters.csv`}
              >
                Export
              </CSVLink>
            </Space>
          ) : null}
        </>
      ),
    },
  ];

  const headers = [
    { label: "Email", key: "email" },
    { label: "Voted At", key: "createdAt" },
  ];

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>

        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card title={moduleNamePural} bordered={false}>
              <Table
                rowKey={(record) => record._id}
                columns={columns}
                dataSource={datas}
                loading={loading}
              />
            </Card>

            <Drawer
              title="Voters"
              placement="right"
              onClose={handleDrawerClose}
              visible={drawerOpen}
              size="large"
              width={1000}
            >
              {data?.voters?.length ? (
                data?.voters?.map((voter, i) => {
                  return (
                    <>
                      <Descriptions
                        bordered
                        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                        title={`Voter ${i + 1}:`}
                        style={{ marginBottom: 20 }}
                      >
                        <Descriptions.Item label="Email">
                          {`${voter.email}`}
                        </Descriptions.Item>
                        <Descriptions.Item label="Voted At">
                          {`${helper.ISTDate(voter.createdAt)}`}
                        </Descriptions.Item>
                      </Descriptions>
                    </>
                  );
                })
              ) : (
                <Result
                  status="404"
                  title="No Voters"
                  // subTitle="Sorry, the page you visited does not exist."
                />
              )}
            </Drawer>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Index;
