import React, { useEffect, useState } from "react";
import { Breadcrumb, Row, Col, Card } from "antd";
import { Table } from "ant-table-extensions";
import http from "../../../helpers/http";
import helper from "../../../helpers/helper";
import config from "../../../config/config";
import moment from "moment";

function Index() {
  const [loading, setloading] = useState(false);

  const [datas, setDatas] = useState([]);

  const moduleNamePural = "Newsletter Form 2024";
  const base_url = config.api_url + "admin/leads/2024/newsletter"; //without trailing slash
  const image_url = config.api_url + ""; //with trailing slash

  const fetchDatas = async () => {
    setloading(true);
    const data = await http.get(base_url);

    if (data) {
      setDatas(data.data);
    }
    setloading(false);
  };

  useEffect(() => {
    fetchDatas();
  }, []);

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email?.localeCompare(b.email),
    },

    {
      title: "Created At",
      render: (text, record) => {
        return helper.ISTDate(record.createdAt);
      },
      key: "createdAt",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    },
  ];


  const fields = {
   
  
    email: "Email",
   

    createdAt: {
      header: "Created At",
      formatter: (_fieldValue, record) => {
        return helper.ISTDate(record.createdAt);
      },
    },
   
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item>Leads</Breadcrumb.Item>
        <Breadcrumb.Item>{moduleNamePural}</Breadcrumb.Item>
      </Breadcrumb>

      <Row>
        <Col span={24}>
          <div className="site-card-border-less-wrapper">
            <Card title={moduleNamePural} bordered={false}>
              <Table
                rowKey={(record) => record._id}
                columns={columns}
                dataSource={datas}
                loading={loading}
                exportableProps={{ showColumnPicker: false, fields,fileName: moduleNamePural }}
                searchable
              />
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Index;
